/*
 * Ajax Fetch content
 *
 * Use to either fetch & replace or fetch and append/preprend content.
 * EX:
 *
 * $('.link-class').on('click',function(e){
 *       e.preventDefault();
 *       window._fetch.replaceContent($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *       window._fetch.appendContent ($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *       window._fetch.prependContent($(this).attr('href'), '.container-to-load-content','.content-to-load');
 *  })
 */

(function ($) {

    // Create job object
    var _fetch = {
        'container': $('body'),
        'callback': '',


        /*******
         * 
         *  Public Functions.
         * 
         * .fetch() = fetches a page
         * .setURL() = Sets the page URL.
         * 
         */

        // Fetch a page
        fetch: function( url, callback, container){
            _fetch.callback = callback;
            if(container){
                _fetch.container = container;
            }
            _fetch._handleRequest( url );
        },
        // Sets the url in the location bar to match the new content
        setURL: function (url) {
            history.pushState({}, '', url);
        },



        // Universal function for handling fetch requests. Given action by calling function
        _handleRequest: function( url ){
            // fetches page
            if(! _fetch.container.hasClass('j-ajax__loading')){
                _fetch._getPage(url);
            }else{
                console.log('already loading');
            }
        },
        // Fetch results page
        _getPage: function(url){
            // Non ajax page reload
            _fetch.container.addClass('j-ajax__loading');
            _fetch.container.trigger('ajaxLoadBegin');

            // ajax request a page
            _fetch.ajax = jQuery.ajax({
                dataType: 'text',
                url: url,

                success: function (rawhtml) {
                    // get results
                    _fetch.container.removeClass('j-ajax__loading');
                    _fetch.container.trigger('ajaxLoadComplete');

                    // run callback function
                    _fetch.callback( $(rawhtml) );
                },

                error: function(){
                    _fetch.container.trigger('ajaxLoadFail');
                }
            });
        },

    };
    // Attach Toolbox to window //
    window._fetch = _fetch;
})(jQuery); // Fully reference jQuery after this point.
