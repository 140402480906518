(function($, window, document) { // jQuery wrapper

    // Page Functions
    headerFunctions = {
        body : $('body'),
        header : $('header'),

        // Code to handle toggling the overlay menu
        menuToggle: function(){
            $('.j-menu-open').on('click', function () {
                $(this).addClass('open');
                headerFunctions.body.addClass('s-menu-open');
            });
            $('.j-menu-close').on('click', function () {
                $(this).removeClass('open');
                headerFunctions.body.removeClass('s-menu-open');
            });
        },

        // Code to handle adding/removing the sticky header css
        stickyHeader: function(){
            headerFunctions.checkStickyHeader();
            $(window).scroll(function() {
                headerFunctions.checkStickyHeader();
            });
        },

        // Helper function that checks position and applys 
        checkStickyHeader: function(){
            var scroll = $(window).scrollTop();
            var targetHeight = headerFunctions.header.height();

            if (scroll >= targetHeight ) {
                headerFunctions.body.addClass('page_is_scrolled');
            } else {
                if (scroll <= targetHeight ) {
                    headerFunctions.body.removeClass('page_is_scrolled');
                }
            }
        }

    };
    window.HeaderFunctions = headerFunctions;
}(window.jQuery, window, document)); // End of jQuery wrapper

